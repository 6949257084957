import React from "react"

import {
  Banner,
  Footer,
  Header,
  Layout,
  Products,
  SectionFeatures,
  SEO,
} from "../components"

const seoDescription = `
  Serwery VPS i dedykowane, dla wymagających aplikacji
`;

const IndexPage = () => (
  <Layout
    header={<Header />}
    footer={
      <>
        <Footer />
      </>
    }
    >
    <SEO title={'seio'} description={seoDescription} />
    <Banner />
    <Products />
    <SectionFeatures />
  </Layout>
);

export default IndexPage;
